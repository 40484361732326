import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { format, parseISO } from 'date-fns';


import { getAllGalleryPictures } from '../../api.controller';

function GalleryPage() {

    const navigate = useNavigate();
    const [user, setUser] = useState();
    const [GalleryPictures, setGalleryPicturesData] = useState([]);




    useEffect(() => {
        const stringifiedUser = localStorage.getItem("userData");
        const _user = JSON.parse(stringifiedUser);
        setUser(_user);
        _getGalleryPictures();
    }, []);


    const _getGalleryPictures = async () => {
        try {
            const response = await getAllGalleryPictures();
            setGalleryPicturesData(response);
            console.log(response);
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <>
            <section className="blog-one blog-one--page">

                <div className="container">
                    <div className="d-flex justify-content-end">
                        {user?.userType === "ADMIN" &&
                            <Link to="/add-gallery-picture" className="hiredots-btn mb-30">
                                Add Picture<i className="fas fa-plus ms-3" />
                            </Link>}
                    </div>

                    <div className="row gutter-y-30">
                        {GalleryPictures.map(picture => (
                            <div key={picture._id} className="col-md-6 col-lg-4">
                                <Link target='_blank' to={`${process.env.REACT_APP_FILE_BASE_URL}/${picture.file}`}>
                                    <div className="blog-card wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="000ms">
                                        <img style={{ width: "100%" }} src={`${process.env.REACT_APP_FILE_BASE_URL}/${picture.file}`} />
                                        <div className="blog-card__content">

                                            <h6 style={{ fontWeight: "normal" }} className="blog-card__title">
                                                {picture.title}
                                            </h6>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </>
    );
}

export default GalleryPage;
