import React from 'react'
import Header from '../Header/Main'
import Breadcrumb from '../Breadcrumb/Main'
import Servicepage from '../Menservice/Servicepage'
import Footer from '../Footer/Main'

function Main() {
    return (
        <>
            <div className="page-wrapper">
                <Header />
                <Breadcrumb subtitle="Home" title="Executive Search" />
                <section className="service-one service-one--page">
                    <div className="container">

                        <p>
                            The pressure is on you to locate your executive team so you can go on to greater and better things without having to scramble to catch up. For more than 10 years, businesses looking for perfectly matched C-level executives have turned to our executive search consultants as their top option. Utilizing our industry-leading evaluation techniques, our executive search services produce outcomes that are unrivaled. In order to create a framework for pay and retention that draws in the best leaders, we assist you in identifying which applicants have the optimal skill set and mentality for the culture of your business. In actuality, candidates hired based on our ratings had an eight-fold higher chance of being promoted within three years.
                        </p>

                        <p>
                            Careers in Ghana (CIG) Executive Search is about finding the right person for the right job, We cannot afford to get it wrong, Our integrity and track record are at stake. This is our competitive advantage and we follow a very thorough process in finding the right candidate.
                        </p>

                        <p>
                            The real value of our business lies in the fact that we are the true brand representatives of our esteemed clients and we are selective about who we represent. Executive Search for Careers in Ghana (CIG) is about confidentiality, integrity, and a straight-talking attitude with a genuine desire to deeply understand and build a long last relationship with our clients and candidates.
                        </p>

                        <p>
                            Our specialist team of recruitment consultants will also perform the following tasks to guarantee the best selection of candidates to meet the human resource requirements of your organization: Pre Screening of candidates, Assessment, Pre-Interview, and Background Screening.
                        </p>

                        <h3 className="mt-4 mb-4">
                            Careers in Ghana (CIG): Multinational Executive Searches Completed
                        </h3>
                        <ul className="about-one__content__list">
                            <li>
                                <span className="fa fa-check-circle" />
                                Regional HR Director - Expro Group Ltd - (UK)
                            </li>
                            <li>
                                <span className="fa fa-check-circle" />
                                Country Rep- Dubai Chamber of Commerce and Industries - (Dubai)
                            </li>
                            <li>
                                <span className="fa fa-check-circle" />
                                Country Director - Safisana Ghana (Netherlands Based)
                            </li>
                            <li>
                                <span className="fa fa-check-circle" />
                                Country Manager -  Webhelp Ghana (USA, France)
                            </li>
                            <li>
                                <span className="fa fa-check-circle" />
                                Senior Cost Accountants - International Finance Corporation IFC (World Bank Group) - Timebridge Holdings, South Africa
                            </li>
                            <li>
                                <span className="fa fa-check-circle" />
                                General Manager Marketing - Aluzinc Asia Pte Ltd - (Singapore)
                            </li>

                            <li>
                                <span className="fa fa-check-circle" />
                                Director EPC Project - DSC Construction Ltd - (India)
                            </li>
                            <li>
                                <span className="fa fa-check-circle" />
                                Regional Corporate Affairs Director - DSC Construction Ltd - (India)
                            </li>
                            <li>
                                <span className="fa fa-check-circle" />
                                Financial Controller - Monurent Ghana - (United Kingdom)
                            </li>
                            <li>
                                <span className="fa fa-check-circle" />
                                HR Generalist - Edcon (Edgar)- (South Africa)
                            </li>
                            <li>
                                <span className="fa fa-check-circle" />
                                HR Manager - MassDiscounter (Game) - (South Africa)
                            </li>
                            <li>
                                <span className="fa fa-check-circle" />
                                Country Manager - GHADABA (Spanish based)
                            </li>
                            <li>
                                <span className="fa fa-check-circle" />
                                Country Director - Luminos Fund - (USA)
                            </li>
                            <li>
                                <span className="fa fa-check-circle" />
                                Tropical Health LLC - (UK)
                            </li>
                            <li>
                                <span className="fa fa-check-circle" />
                                Debit Collector - Unified Credit Solutions DMCC - (Dubai)
                            </li>
                            <li>
                                <span className="fa fa-check-circle" />
                                Unateus Ghana - (Russia)
                            </li>
                        </ul>


                    </div>
                </section>
                <Footer />
            </div>

        </>
    )
}

export default Main