import React from 'react'
import Header from '../Header/Main'
import Breadcrumb from '../Breadcrumb/Main'

import Footer from '../Footer/Main'
import AddCareerListingForm from '../AddCareerListingForm/Main'

function Main() {
  return (
    <div>
      <div className="page-wrapper">
        <Header />
        <Breadcrumb subtitle="Home" title="Add Blog" />
        <AddCareerListingForm />
        <Footer />
      </div>
    </div>
  )
}

export default Main