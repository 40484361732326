import React from 'react'
import Slider from 'react-slick'
import Branding from '../../assets/images/resources/brand-1-1.png'
import Jica from '../../assets/images/resources/jica.png'
import Expro from '../../assets/images/resources/expro.png'
import Nespresso from '../../assets/images/resources/nespresso.png'
import Dow from '../../assets/images/resources/dow.png'
import Shimzu from '../../assets/images/resources/shimzu.png'
import DubaiChamber from '../../assets/images/resources/dubai-chamber.png'
import Brussels from '../../assets/images/resources/brussels.png'
import WoolWorths from '../../assets/images/resources/woolworths.png'
import BestWestern from '../../assets/images/resources/best-western.png'
import MonuRent from '../../assets/images/resources/monurent.png'
import DHL from '../../assets/images/resources/dhl.png'
import DRA from '../../assets/images/resources/dra.png'
import Landcaster from '../../assets/images/resources/landcaster.png'
import EnterpriseLife from '../../assets/images/resources/enterprise-life.png'


const Clientslider = {
    items: 5,
    margin: 65,
    smartspeed: 1000,
    loop: true,
    autoplay: true,
    arrows: false,
    responsive: [
        {
            breakpoint: 576,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                margin: 30,
            },
        },
        {
            breakpoint: 992,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                margin: 30,
            },
        },
    ],
}

function Client({ hideTitle = false }) {
    return (
        <div>
            <div className="client-carousel ">
                <div className="container">
                    <div className="row">
                        {!hideTitle && <div className="col-xl-3">
                            <h3 className="client-carousel__title">
                                Trusted by more
                                <br /> then 20+ companies
                            </h3>
                        </div>}
                        <div className={!hideTitle ? "col-xl-9" : "col-xl-12"}>
                            <Slider className='client-one'{...Clientslider} slidesToShow={5} slidesToScroll={1} autoplay={true} autoplaySpeed={1000}>
                                <div className="client-carousel__one__item">
                                    <img src={Jica} alt="jica" />
                                </div>
                                <div className="client-carousel__one__item">
                                    <img src={Nespresso} alt="nespresso" />
                                </div>
                                <div className="client-carousel__one__item">
                                    <img src={Expro} alt="expro" />
                                </div>
                                <div className="client-carousel__one__item">
                                    <img src={Dow} alt="dow" />
                                </div>
                                <div className="client-carousel__one__item">
                                    <img src={Shimzu} alt="shimzu" />
                                </div>
                                <div className="client-carousel__one__item">
                                    <img src={DubaiChamber} alt="dubai chamber" />
                                </div>
                                <div className="client-carousel__one__item">
                                    <img src={Brussels} alt="brussels" />
                                </div>
                                <div className="client-carousel__one__item">
                                    <img src={WoolWorths} alt="woolworths" />
                                </div>
                                <div className="client-carousel__one__item">
                                    <img src={BestWestern} alt="best western premier hotel" />
                                </div>
                                <div className="client-carousel__one__item">
                                    <img src={MonuRent} alt="monurent" />
                                </div>
                                <div className="client-carousel__one__item">
                                    <img src={DHL} alt="dhl" />
                                </div>
                                <div className="client-carousel__one__item">
                                    <img src={DRA} alt="dra" />
                                </div>
                                <div className="client-carousel__one__item">
                                    <img src={Landcaster} alt="landcaster university" />
                                </div>
                                <div className="client-carousel__one__item">
                                    <img src={EnterpriseLife} alt="enterprise-life" />
                                </div>
                            </Slider>
                        </div>
                    </div>
                    <div className="client-carousel__border" />
                </div>
            </div>
        </div>
    )
}

export default Client