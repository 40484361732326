import React, { useEffect, useRef, useState } from 'react';
import { createJob } from '../../api.controller';
import Swal from 'sweetalert2';
import { showNotification, showLoading } from '../../utils.ts';



function Main() {
    const [benefits, setBenefits] = useState([]);
    const [currentBenefit, setCurrentBenefit] = useState("");
    const [responsibilities, setResponsibilities] = useState([]);
    const [currentResponsibility, setCurrentResponsibility] = useState("");
    const [requirementsAndQualifications, setRequirementsAndQualifications] = useState([]);
    const [currentRequirementsAndQualification, setCurrentRequirementsAndQualification] = useState("");
    const companyLogoRef = useRef(null);
    let [formData, setFormData] = useState({
        jobTitle: "",
        companyName: "",
        companyLocation: "Unspecified",
        salary: "",
        workingLocationPolicy: "",
        jobType: "",
        minimumEducationQualification: "",
        minimumExperience: "",
        benefits: [],
        expiresOn: "",
        quickSummary: "",
        responsibilities: [],
        requirementsAndQualifications: [],
        applicationLink: "",
        companyLogo: null
    });


    useEffect(() => {
        setFormData({ ...formData, benefits })
    }, [benefits]);

    useEffect(() => {
        setFormData({ ...formData, responsibilities })
    }, [responsibilities]);

    useEffect(() => {
        setFormData({ ...formData, requirementsAndQualifications })
    }, [requirementsAndQualifications]);

    const resetCompanyLogoInput = () => {
        companyLogoRef.current.value = null;
    }

    const _createJob = async () => {
        console.log(formData);
        const keysToStringify = ["benefits", "responsibilities", "requirementsAndQualifications"];
        const data = new FormData();

        for (const key in formData) {
            if (Object.hasOwnProperty.call(formData, key)) {
                const item = formData[key];
                if (keysToStringify.includes(key)) {
                    data.append(key, JSON.stringify(item));
                } else {
                    data.append(key, item);
                }
            }
        }


        if (!formData.jobTitle) {
            showNotification("An Error Occured", "Job Title is required", "error");
            return;
        }

        if (!formData.jobType) {
            showNotification("An Error Occured", "Job Type is required", "error");
            return;
        }

        if (!formData.companyName) {
            showNotification("An Error Occured", "Company Name is required", "error");
            return;
        }

        if (!formData.quickSummary) {
            showNotification("An Error Occured", "Quick Summary is required", "error");
            return;
        }
        try {
            showLoading("Please wait", "Submitting Data");
            const response = await createJob(data);
            Swal.close();
            showNotification("Great!", "Job has been successfully created", "success");
            setFormData({
                jobTitle: "",
                companyName: "",
                companyLocation: "Unspecified",
                salary: "",
                workingLocationPolicy: "",
                jobType: "",
                minimumEducationQualification: "",
                minimumExperience: "",
                benefits: [],
                expiresOn: "",
                quickSummary: "",
                responsibilities: [],
                requirementsAndQualifications: [],
                applicationLink: "",
                companyLogo: null
            });
            resetCompanyLogoInput();
            console.log(response);
        } catch (error) {
            const message = error?.response?.data?.message || "An error occured";
            showNotification("Sorry", message, "error");
            console.log(error);
        }
    }
    return (
        <div>
            <section className="contact-one pt-100 pb-100">
                <div className="container">
                    <div className="contact-one__inner">
                        <div className="row">
                            <div className="col-lg-8">
                                <div className="contact-one__content">
                                    <div className="sec-title text-@@textAlign">
                                        <h6 className="sec-title__tagline">Add Job</h6>
                                        <h3 className="sec-title__title">
                                            Feel Free to share job openings<br /> with Careers In Ghana
                                        </h3>
                                    </div>
                                    <p className="contact-one__text">
                                        Careers In Ghana is dedicated to connecting top talent with leading employers across the country. We invite you to share your job openings with us and benefit from our extensive network and resources.
                                    </p>

                                </div>
                                <div >
                                    <form className=" contact-form-validated form-one wow fadeInUp" data-wow-duration="1500ms" >
                                        <div className="form-one__group">

                                            <div className="form-one__control form-one__control--full">
                                                <input type="text"
                                                    value={formData.jobTitle}
                                                    onChange={(event) => {
                                                        const jobTitle = event.target.value;
                                                        setFormData({ ...formData, jobTitle });
                                                    }}
                                                    name="jobTitle" placeholder="Job Title" />
                                            </div>
                                            <div className="form-one__control form-one__control--full">
                                                <input type="text"
                                                    value={formData.companyName}
                                                    onChange={(event) => {
                                                        const companyName = event.target.value;
                                                        setFormData({ ...formData, companyName });
                                                    }}
                                                    name="company_name" placeholder="Company Name" />
                                            </div>
                                            <div className="form-one__control form-one__control--full">
                                                <select
                                                    value={formData.companyLocation}
                                                    onChange={(event) => {
                                                        const companyLocation = event.target.value;
                                                        setFormData({ ...formData, companyLocation });
                                                    }}
                                                    name="companyLocation">
                                                    <option value='Unspecified'>Select Company Location</option>
                                                    <option value='Ahafo'>Ahafo</option>
                                                    <option value='Ashanti'>Ashanti</option>
                                                    <option value='Bono'>Bono</option>
                                                    <option value='Bono East'>Bono East</option>
                                                    <option value='Central'>Central</option>
                                                    <option value='Eastern'>Eastern</option>
                                                    <option value='Greater Accra'>Greater Accra</option>
                                                    <option value='North East'>North East</option>
                                                    <option value='Northern'>Northern</option>
                                                    <option value='Oti'>Oti</option>
                                                    <option value='Savannah'>Savannah</option>
                                                    <option value='Upper East'>Upper East</option>
                                                    <option value='Upper West'>Upper West</option>
                                                    <option value='Volta'>Volta</option>
                                                    <option value='Western'>Western</option>
                                                    <option value='Western North'>Western North</option>


                                                </select>
                                            </div>
                                            <div className="form-one__control form-one__control--full">
                                                <input
                                                    value={formData.salary}
                                                    onChange={(event) => {
                                                        const salary = event.target.value;
                                                        setFormData({ ...formData, salary });
                                                    }}
                                                    type="number" min="0" name="salary" placeholder="Salary (GHS)" />
                                            </div>
                                            <div className="form-one__control form-one__control--full">
                                                <select
                                                    value={formData.workingLocationPolicy}
                                                    onChange={(event) => {
                                                        const workingLocationPolicy = event.target.value;
                                                        setFormData({ ...formData, workingLocationPolicy });
                                                    }}
                                                    name="workingLocationPolicy">
                                                    <option value='Unspecified'>Select Working Location Policy</option>
                                                    <option value='On-Site'>On-Site</option>
                                                    <option value='Remote'>Remote</option>
                                                    <option value='Hybrid'>Hybrid</option>
                                                    <option value='Flexible'>Flexible</option>
                                                    <option value='Field-Based'>Field-Based</option>
                                                    <option value='Telecommuting'>Telecommuting</option>
                                                    <option value='Satellite Office' >Satellite Office</option>

                                                </select>
                                            </div>
                                            <div className="form-one__control form-one__control--full">
                                                <select
                                                    value={formData.jobType}
                                                    onChange={(event) => {
                                                        const jobType = event.target.value;
                                                        setFormData({ ...formData, jobType });
                                                    }}
                                                    name="jobType">
                                                    <option value='Unspecified'>Select Job Type</option>
                                                    <option value='Full-Time'>Full-Time</option>
                                                    <option value='Part-Time'>Part-Time</option>
                                                    <option value='Contract'>Contract</option>
                                                    <option value='Temporary'>Temporary</option>
                                                    <option value='Internship'>Internship</option>
                                                    <option value='Apprenticeship'>Apprenticeship</option>
                                                    <option value='Volunteer'>Volunteer</option>

                                                </select>
                                            </div>
                                            <div className="form-one__control form-one__control--full">
                                                <input
                                                    value={formData.minimumEducationQualification}
                                                    onChange={(event) => {
                                                        const minimumEducationQualification = event.target.value;
                                                        setFormData({ ...formData, minimumEducationQualification });
                                                    }}
                                                    type="text" name="minimumEducationQualification" placeholder="Minimum Education Qualification" />
                                            </div>
                                            <div className="form-one__control form-one__control--full">
                                                <input type="number" min="0" name="minimumExperience" placeholder="Minimum Experience" />
                                            </div>

                                            <div className="row w-100 form-one__control--full">
                                                <div className="col-md-6">
                                                    <div className="form-one__control form-one__control--full">
                                                        <input type="text" value={currentBenefit} onChange={(event) => {
                                                            setCurrentBenefit(event.target.value);
                                                        }} name="benefits" placeholder="Enter Benefit" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div>
                                                        <button onClick={(event) => {
                                                            event.preventDefault();
                                                            const benefit = currentBenefit;
                                                            setBenefits([...benefits, benefit]);

                                                            setCurrentBenefit("");
                                                        }} className="hiredots-btn">
                                                            Add Benefit <i className="fas fa-plus ms-3" />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-one__control--full">
                                                {benefits.map(benefit => (<div style={{ background: "#00cee522" }} className="d-flex justify-content-between p-2 px-4 rounded-2 mb-2  w-100">{benefit}<span onClick={() => {
                                                    const newBenefits = benefits.filter(item => item != benefit);
                                                    setBenefits(newBenefits);
                                                }} style={{ background: "#00cee5", cursor: "pointer" }} className="text-white p-1 px-2 fw-bold rounded ">remove</span></div>))}
                                            </div>
                                            <div className="form-one__control form-one__control--full">
                                                <label>Expires On</label>
                                                <input value={formData.expiresOn}
                                                    onChange={(event) => {
                                                        const expiresOn = event.target.value;
                                                        setFormData({ ...formData, expiresOn });
                                                    }} type="date" name="expiresOn" placeholder="Expires On" />
                                            </div>


                                            <div className="form-one__control form-one__control--full">
                                                <textarea
                                                    value={formData.quickSummary}
                                                    onChange={(event) => {
                                                        const quickSummary = event.target.value;
                                                        setFormData({ ...formData, quickSummary });
                                                    }}
                                                    name="quickSummary" placeholder="Quick Summary" defaultValue={""} />
                                            </div>

                                            <div className="row w-100 form-one__control--full">
                                                <div className="col-md-6">
                                                    <div className="form-one__control form-one__control--full">
                                                        <input

                                                            type="text" value={currentResponsibility} onChange={(event) => {
                                                                setCurrentResponsibility(event.target.value);
                                                            }} name="responsibilities" placeholder="Enter Responsibility" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div>
                                                        <button onClick={(event) => {
                                                            event.preventDefault();
                                                            const responsibility = currentResponsibility;
                                                            setResponsibilities([...responsibilities, responsibility]);
                                                            setCurrentResponsibility("");
                                                        }} className="hiredots-btn">
                                                            Add Responsibility <i className="fas fa-plus ms-3" />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-one__control--full">
                                                {responsibilities.map(responsibility => (<div style={{ background: "#00cee522" }} className="d-flex justify-content-between p-2 px-4 rounded-2 mb-2  w-100">{responsibility}<span onClick={() => {
                                                    const newResponsibilities = responsibilities.filter(item => item != responsibility);
                                                    setResponsibilities(newResponsibilities);
                                                }} style={{ background: "#00cee5", cursor: "pointer" }} className="text-white p-1 px-2 fw-bold rounded ">remove</span></div>))}
                                            </div>

                                            <div className="row w-100 form-one__control--full">
                                                <div className="col-md-6">
                                                    <div className="form-one__control form-one__control--full">
                                                        <input type="text" value={currentRequirementsAndQualification} onChange={(event) => {
                                                            setCurrentRequirementsAndQualification(event.target.value);
                                                        }} name="currentRequirementsAndQualifications" placeholder="Enter Requirement/Qualification" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div>
                                                        <button onClick={(event) => {
                                                            event.preventDefault();
                                                            const requirementsAndQualification = currentRequirementsAndQualification;
                                                            setRequirementsAndQualifications([...requirementsAndQualifications, requirementsAndQualification]);
                                                            setCurrentRequirementsAndQualification("");
                                                        }} className="hiredots-btn">
                                                            Add Requirement <i className="fas fa-plus ms-3" />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-one__control--full">
                                                {requirementsAndQualifications.map(requirement => (<div style={{ background: "#00cee522" }} className="d-flex justify-content-between p-2 px-4 rounded-2 mb-2  w-100">{requirement}<span onClick={() => {
                                                    const newRequirementsAndQualifications = requirementsAndQualifications.filter(item => item != requirement);
                                                    setRequirementsAndQualifications(newRequirementsAndQualifications);
                                                }} style={{ background: "#00cee5", cursor: "pointer" }} className="text-white p-1 px-2 fw-bold rounded ">remove</span></div>))}
                                            </div>

                                            <div className="form-one__control form-one__control--full">
                                                <input type="text"
                                                    value={formData.applicationLink}
                                                    onChange={(event) => {
                                                        const applicationLink = event.target.value;
                                                        setFormData({ ...formData, applicationLink });
                                                    }}
                                                    name="applicationLink" placeholder="Application Link" />
                                            </div>

                                            <div className="form-one__control form-one__control--full mt-3">
                                                <div>
                                                    <label>Upload Job Logo</label>
                                                </div>
                                                <input ref={companyLogoRef} onChange={(event) => {
                                                    setFormData({ ...formData, companyLogo: event.target.files[0] });
                                                }} type="file" name="companyLogo" placeholder="Logo" />
                                            </div>
                                            <div className="form-one__control form-one__control--full">
                                                <button onClick={(event) => {
                                                    event.preventDefault();
                                                    _createJob();

                                                }} type="submit" className="hiredots-btn hiredots-btn--white">
                                                    <span>Submit</span>
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Main