import React from 'react'

function Map() {
    return (
        <div>
            <section className="contact-map">
                <div className="container">
                    <div className="google-map google-map__contact">
                        <iframe
                            title="template google map"
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d63530.77800354308!2d-0.14784334248839864!3d5.615120878242249!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xfdf85090bce8e55%3A0x825b2997edb979d3!2sCareers%20In%20Ghana%20%3A%20Recruitment%20Consultancy!5e0!3m2!1sen!2sgh!4v1715695907565!5m2!1sen!2sgh"
                            className="map__contact"
                            allowFullScreen=""
                        />
                    </div>
                    {/* /.google-map */}
                </div>
                {/* /.container-fluid */}
            </section>

        </div>
    )
}

export default Map