import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { format, parseISO } from 'date-fns';

import Blog1 from '../../assets/images/blog/blog-1-1.jpg';
import Blog2 from '../../assets/images/blog/blog-1-2.jpg';
import Blog3 from '../../assets/images/blog/blog-1-3.jpg';
import Blog4 from '../../assets/images/blog/blog-1-4.jpg';
import Blog5 from '../../assets/images/blog/blog-1-5.jpg';
import Blog6 from '../../assets/images/blog/blog-1-6.jpg';
import { getAllCareerListingData } from '../../api.controller';

function CareerListingPage() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const category = queryParams.get("category");
    const navigate = useNavigate();
    const [user, setUser] = useState();
    const [careerListingData, setCareerListingData] = useState([]);
    const [categories, setCategories] = useState([
        {
            value: "ALL",
            label: "All"
        },
        {
            value: "BUSINESS_AND_FINANCE",
            label: "Business and Finance"
        },
        {
            value: "TECHNICAL_AND_COMPUTER",
            label: "Technical and Computer"
        },
        {
            value: "HEALTH_SECTOR",
            label: "Health Sector"
        },
        {
            value: "SERVICES_SECTOR",
            label: "Services Sector"
        },
        {
            value: "EDUCATION_SECTOR",
            label: "Education Sector"
        }
    ]);
    const [selectedCategory, setSelectedCategory] = useState(category || "ALL");


    useEffect(() => {
        const stringifiedUser = localStorage.getItem("userData");
        const _user = JSON.parse(stringifiedUser);
        setUser(_user);
        const _selectedCategory = category || "ALL";
        _getCareerListingData(_selectedCategory);
        setSelectedCategory(_selectedCategory);
    }, [category]);


    const _getCareerListingData = async (_selectedCategory) => {
        try {
            const response = await getAllCareerListingData(_selectedCategory);
            setCareerListingData(response);
            console.log(response);
        } catch (error) {
            console.log(error);
        }
    }

    // const careerListingData = [
    //     { id: 1, image: Blog1, date: '30 Aug', title: 'Guide to HR Adviser and Clients Lessening', info: 'Lorem ipsum is simply free dolor sit amet, consect pisicing elit.' },
    //     { id: 2, image: Blog2, date: '30 Aug', title: 'Top Skin care tips for oily skins', info: 'Lorem ipsum is simply free dolor sit amet, consect pisicing elit.' },
    //     { id: 3, image: Blog3, date: '30 Aug', title: 'Outstanding island for winter sun', info: 'Lorem ipsum is simply free dolor sit amet, consect pisicing elit.' },
    //     { id: 4, image: Blog4, date: '30 Aug', title: 'My supervisor didn\'t like the latest', info: 'Lorem ipsum is simply free dolor sit amet, consect pisicing elit.' },
    //     { id: 5, image: Blog5, date: '30 Aug', title: 'We need to build it so that it scales', info: 'Lorem ipsum is simply free dolor sit amet, consect pisicing elit.' },
    //     { id: 6, image: Blog6, date: '30 Aug', title: 'Big picture organic growth, or fast', info: 'Lorem ipsum is simply free dolor sit amet, consect pisicing elit.' }
    // ];

    return (
        <>
            <section className="blog-one blog-one--page">

                <div className="container">
                    <div className="d-flex justify-content-end">
                        {user?.userType === "ADMIN" &&
                            <Link to="/add-career-listing" className="hiredots-btn mb-30">
                                Add Career Listing<i className="fas fa-plus ms-3" />
                            </Link>}
                    </div>
                    <div className="row mb-60">
                        <div className="d-flex flex-wrap">
                            {categories.map(category => (<div onClick={() => {
                                navigate(`/career-listings?category=${category.value}`)
                            }} className={selectedCategory == category.value ? `chip-btn p-2 px-4 me-2 mb-3 fw-bold rounded-5 selected` : `chip-btn p-2 px-4 me-2 mb-3 fw-bold rounded-5 `}>
                                {category.label}
                            </div>))}
                        </div>
                    </div>
                    <div className="row gutter-y-30">
                        {careerListingData.map(careerListing => (
                            <div key={careerListing._id} className="col-md-6 col-lg-4">
                                <div className="blog-card wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="000ms">
                                    {/* <div className="blog-card__image">
                                        <img src={`${process.env.REACT_APP_FILE_BASE_URL}/${blog.image}`} alt={blog.title} />
                                    </div> */}
                                    <div className="blog-card__content">

                                        <ul className="list-unstyled blog-card__meta">
                                            {/* <li>
                                                <Link to="/blog">
                                                    <i className="fas fa-tags" />
                                                    Business
                                                </Link>
                                            </li> */}
                                            {/* <li>
                                                <Link to="/blog-details">
                                                    <i className="fa fa-comments" /> 2 Comments
                                                </Link>
                                            </li> */}
                                        </ul>
                                        <h3 className="blog-card__title">
                                            <Link to={`${process.env.REACT_APP_FILE_BASE_URL}/${careerListing.file}`}><i class="fas fa-file-pdf me-1" /> {careerListing.title}</Link>
                                        </h3>
                                        <p className="blog-card__info" >{careerListing.shortDescription}</p>
                                        <Link to={`${process.env.REACT_APP_FILE_BASE_URL}/${careerListing.file}`} className="blog-card__link">
                                            Read more
                                            <i className="icon-right-arrow" />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </>
    );
}

export default CareerListingPage;
