import React, { useEffect, useRef, useState } from 'react';
import { createCareerListing } from '../../api.controller.js';
import Swal from 'sweetalert2';
import { showNotification, showLoading } from '../../utils.ts';



function Main() {
    const fileRef = useRef(null);
    let [formData, setFormData] = useState({
        title: "",
        category: "",
        shortDescription: "",
        file: null
    });




    const resetFile = () => {
        fileRef.current.value = null;
    }

    const _createCareerListing = async () => {
        const data = new FormData();
        for (const key in formData) {
            if (Object.hasOwnProperty.call(formData, key)) {
                const item = formData[key];
                data.append(key, item);
            }
        }


        if (!formData.title) {
            showNotification("An Error Occured", "Title is required", "error");
            return;
        }

        if (!formData.category) {
            showNotification("An Error Occured", "Category is required", "error");
            return;
        }

        if (!formData.shortDescription) {
            showNotification("An Error Occured", "Short Description is required", "error");
            return;
        }

        if (!fileRef.current.value) {
            showNotification("An Error Occured", "File is required", "error");
            return;
        }
        try {
            showLoading("Please wait", "Submitting Data");
            const response = await createCareerListing(data);
            Swal.close();
            showNotification("Great!", "Career listing has been successfully created", "success");
            setFormData({
                title: "",
                category: "",
                shortDescription: "",
                file: null
            });
            resetFile();
            console.log(response);
        } catch (error) {
            const message = error?.response?.data?.message || "An error occured";
            showNotification("Sorry", message, "error");
            console.log(error);
        }
    }
    return (
        <div>
            <section className="contact-one pt-100 pb-100">
                <div className="container">
                    <div className="contact-one__inner">
                        <div className="row">
                            <div className="col-lg-8">
                                <div className="contact-one__content">
                                    <div className="sec-title text-@@textAlign">
                                        <h6 className="sec-title__tagline">Add Career Listing</h6>
                                        {/* <h3 className="sec-title__title">
                                            Feel Free to share job openings<br /> with Careers In Ghana
                                        </h3> */}
                                    </div>
                                    {/* <p className="contact-one__text">
                                        Careers In Ghana is dedicated to connecting top talent with leading employers across the country. We invite you to share your job openings with us and benefit from our extensive network and resources.
                                    </p> */}

                                </div>
                                <div >
                                    <form className=" contact-form-validated form-one wow fadeInUp" data-wow-duration="1500ms" >
                                        <div className="form-one__group">

                                            <div className="form-one__control form-one__control--full">
                                                <input type="text"
                                                    value={formData.title}
                                                    onChange={(event) => {
                                                        const title = event.target.value;
                                                        setFormData({ ...formData, title });
                                                    }}
                                                    name="title" placeholder="Title" />
                                            </div>
                                            <div className="form-one__control form-one__control--full">
                                                <input type="text"
                                                    value={formData.shortDescription}
                                                    onChange={(event) => {
                                                        const shortDescription = event.target.value;
                                                        setFormData({ ...formData, shortDescription });
                                                    }}
                                                    name="shortDescription" placeholder="Short Description" />
                                            </div>
                                            <div className="form-one__control form-one__control--full">
                                                <select
                                                    value={formData.category}
                                                    onChange={(event) => {
                                                        const category = event.target.value;
                                                        setFormData({ ...formData, category });
                                                    }}
                                                    name="category">
                                                    <option value='Unspecified'>Select Category</option>
                                                    <option value='BUSINESS_AND_FINANCE'>Business and Finance</option>
                                                    <option value='TECHNICAL_AND_COMPUTER'>Technical and Computer</option>
                                                    <option value='HEALTH_SECTOR'>Health Sector</option>
                                                    <option value='SERVICES_SECTOR'>Services Sector</option>
                                                    <option value='EDUCATION_SECTOR'>Education Sector</option>
                                                </select>
                                            </div>


                                            <div className="form-one__control form-one__control--full mt-3">
                                                <div>
                                                    <label>Upload File</label>
                                                </div>
                                                <input ref={fileRef} onChange={(event) => {
                                                    setFormData({ ...formData, file: event.target.files[0] });
                                                }} type="file" name="file" placeholder="File" />
                                            </div>
                                            <div className="form-one__control form-one__control--full">
                                                <button onClick={(event) => {
                                                    event.preventDefault();
                                                    _createCareerListing();

                                                }} type="submit" className="hiredots-btn hiredots-btn--white">
                                                    <span>Submit</span>
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Main