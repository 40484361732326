import React, { useEffect, useRef, useState } from 'react';
import { createBlog } from '../../api.controller.js';
import Swal from 'sweetalert2';
import { showNotification, showLoading } from '../../utils.ts';
import Editor from 'react-simple-wysiwyg';



function Main() {
    const imageRef = useRef(null);
    let [formData, setFormData] = useState({
        title: "",
        content: "",
        author: "",
        image: null
    });


    const [user, setUser] = useState();

    useEffect(() => {
        const stringifiedUser = localStorage.getItem("userData");
        const _user = JSON.parse(stringifiedUser);
        setUser(_user);
    }, []);

    useEffect(() => {
        if (user) {
            setFormData({ ...formData, author: user.fullName });
        }
    }, [user]);

    const resetBlogImage = () => {
        imageRef.current.value = null;
    }

    const _createBlog = async () => {
        console.log(formData);
        const data = new FormData();

        for (const key in formData) {
            if (Object.hasOwnProperty.call(formData, key)) {
                const item = formData[key];
                data.append(key, item);
            }
        }




        if (!formData.title) {
            showNotification("An Error Occured", "Title is required", "error");
            return;
        }


        if (!formData.content) {
            showNotification("An Error Occured", "Content is required", "error");
            return;
        }


        try {
            showLoading("Please wait", "Submitting Data");

            const response = await createBlog(data);
            Swal.close();
            showNotification("Great!", "Blog has been successfully created", "success");
            setFormData({
                title: "",
                content: "",
                author: "",
                image: null
            });
            resetBlogImage();

        } catch (error) {
            const message = error?.response?.data?.message || "An error occured";
            showNotification("Sorry", message, "error");
            console.log(error);
        }
    }
    return (
        <div>
            <section className="contact-one pt-100 pb-100">
                <div className="container">
                    <div className="contact-one__inner">
                        <div className="row">
                            <div className="col-lg-8">
                                <div className="contact-one__content">
                                    <div className="sec-title text-@@textAlign">
                                        <h6 className="sec-title__tagline">Add Blog</h6>
                                    </div>
                                </div>
                                <div >
                                    <form className=" contact-form-validated form-one wow fadeInUp" data-wow-duration="1500ms" >
                                        <div className="form-one__group">

                                            <div className="form-one__control form-one__control--full mt-3">
                                                <div>
                                                    <label>Upload Image</label>
                                                </div>
                                                <input ref={imageRef} onChange={(event) => {
                                                    setFormData({ ...formData, image: event.target.files[0] });
                                                }} type="file" name="image" placeholder="Blog Image" />
                                            </div>

                                            <div className="form-one__control form-one__control--full">
                                                <input type="text"
                                                    value={formData.title}
                                                    onChange={(event) => {
                                                        const title = event.target.value;
                                                        setFormData({ ...formData, title });
                                                    }}
                                                    name="title" placeholder="Title" />
                                            </div>

                                            <div className="wysiwyg-wrapper form-one__control form-one__control--full">
                                                <Editor style={{ width: "100%", height: "100%" }} placeholder="Content" value={formData.content} onChange={(e) => {
                                                    const content = e.target.value;
                                                    setFormData({ ...formData, content });
                                                }} />
                                            </div>

                                            <div className="form-one__control form-one__control--full">
                                                <button onClick={(event) => {
                                                    event.preventDefault();
                                                    _createBlog();
                                                }} type="submit" className="hiredots-btn hiredots-btn--white">
                                                    <span>Submit</span>
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Main