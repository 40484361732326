import React from 'react'
import Header from '../Header/Main'
import Breadcrumb from '../Breadcrumb/Main'
import Servicepage from '../Menservice/Servicepage'
import Footer from '../Footer/Main'

function Main() {
    return (
        <>
            <div className="page-wrapper">
                <Header />
                <Breadcrumb subtitle="Home" title="Recruitment" />
                <section className="service-one service-one--page">
                    <div className="container">

                        <p>
                            We focus on attracting hard to reach Ghanaian candidates from around the world with that potent mix of international experience combined with local knowledge. As a comprehensive supplier in executive recruitment for lower, mid to senior levels, At Careers in Ghana our simple philosophy is to attract high-caliber Ghanaian talent from around the world, ensuring you recruit the best in the market.
                        </p>
                        <p>
                            We specialize in recruiting for the following sectors Business & Financial Services, Banking & Insurance, Media and Creative Service, Engeering, Energy, Oil & Gas, Phamaceutical, Manufacturing, Mining, Information Technology, Telocommunication, Logistics, Real Estate, Construction, Hospitality, Education, FMCG, Retail and Agricultural.
                        </p>
                        <p>
                            Careers in Ghana (CIG) has completed a wide range of recruitment for multinational companies from South Africa, UK, USA, Canada, Spain, India, Dubia, Singapore, Italy and France.
                        </p>
                        <p>
                            Our specialist team of recruitment consultants will also perform the following tasks to guarantee the best selection of candidates to meet the human resource requirements of your organisation : Pre Screening of candidate, Assessment, Pre-Interview, Background Screening.
                        </p>



                        <h3 className="mt-5">
                            We Offer the following recruitment types
                        </h3>
                        <ul className="about-one__content__list">
                            <li>
                                <span className="fa fa-check-circle" />
                                Permanent Recruitment
                            </li>
                            <li>
                                <span className="fa fa-check-circle" />
                                Temporary Staffing
                            </li>

                        </ul>


                    </div>
                </section>
                <Footer />
            </div>

        </>
    )
}

export default Main