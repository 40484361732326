import React from 'react'
import Header from '../Header/Main'
import Breadcrumb from '../Breadcrumb/Main'
import Servicepage from '../Menservice/Servicepage'
import Footer from '../Footer/Main'
import Nexford from '../../assets/images/resources/nexford.jpg'

function Main() {
    return (
        <>
            <div className="page-wrapper">
                <Header />
                <Breadcrumb subtitle="Home" title="Nexford University" />
                <section className="service-one service-one--page">
                    <div className="container">

                        {/* <p>
                            By managing your workforce through outsourcing, we will provide an alternative to the high cost of retaining full time labour and a more effective staff management system for your workforce to meet your needs. With regards to your staff maintenance, initial training and replacement needs, they become our issue and no longer yours.

                        </p>
                        <h3 className="mt-4">
                            HR Outsourcing (Permanent and Temporary staff)
                        </h3>
                        <p>
                            Careers in Ghana (CIG) seek to ease the financial and administrative burdens that are often associated with the hiring of contract labour. Careers in Ghana (CIG) will take up the task of salary and payroll matters, so that your company can concentrate on the task at hand, and we will relieve your company of the monotonous and tedious job of managing payrolls. Based on the clients' project demands or assignment nature, the staff can be placed under our company's payroll.
                        </p>
                        <ul className="about-one__content__list">
                            <li>
                                <span className="fa fa-check-circle" />
                                Permanent Recruitment
                            </li>
                            <li>
                                <span className="fa fa-check-circle" />
                                Temporary Staffing
                            </li>
                            <li>
                                <span className="fa fa-check-circle" />
                                Payroll administration</li>
                            <li>
                                <span className="fa fa-check-circle" />
                                Maintenance of personnel database</li>
                            <li>
                                <span className="fa fa-check-circle" />
                                Provision of intranet service which set out HR policies</li>
                            <li>
                                <span className="fa fa-check-circle" />
                                Recruitment administration</li>
                            <li>
                                <span className="fa fa-check-circle" />
                                Routine training service</li>

                        </ul>

                        <h3 className="mt-4">Payroll Outsourcing</h3>
                        <p>
                            For any business, managing payroll is indeed a dreadful task as it consumes a lot of man hours and is not also income generating. Besides being cumbersome, it is also tiring as rules and regulations keep changing and therefore, software needs to be updated and employees are also required to abreast themselves with new tools. So to get away this tedious chore, why not outsource your payroll processing to Careers in Ghana? All you would need to do is provide us the payroll data and we will give you a viable, confidential, and accurate payroll processing service
                        </p>

                        <h3 className="mt-4">We deliver on:</h3>
                        <ul className="about-one__content__list">

                            <li>
                                <span className="fa fa-check-circle" />
                                Payroll processing that integrates with an accounting system. Payroll tax filing</li>
                            <li>
                                <span className="fa fa-check-circle" />
                                Employee self-service anytime, anywhere, from any device</li>
                            <li>
                                <span className="fa fa-check-circle" />
                                Employees pay options, including direct deposit, Mobile Money</li>
                            <li>
                                <span className="fa fa-check-circle" />
                                Year-end processing, bonuses</li>
                            <li>
                                <span className="fa fa-check-circle" />
                                Employee recordkeeping</li>
                            <li>
                                <span className="fa fa-check-circle" />
                                Ghana Revenue Authority (GRA) Services</li>
                            <li>
                                <span className="fa fa-check-circle" />
                                Social Security Administration</li>

                        </ul> */}
                        <div>
                            <h1>Nexford University (American MBA)</h1>
                            <p>
                                What if you were told that you could access better opportunities from the comfort of your own home in Ghana?
                                At Nexford University, that's exactly what you can do. Continue your journey to the top by acquiring
                                future-focused skills with an MBA from Nexford!
                            </p>
                            <h2>Nexford offers:</h2>
                            <ul>
                                <li>A 100% online, affordable American MBA you can study at home in Ghana ($160/month, average $2,880 total degree cost)</li>
                                <li>Flexible learning: study whenever, wherever.</li>
                                <li>Monthly payments in Cedi or USD</li>
                            </ul>
                            <p>
                                Want a degree to help you stand out from the crowd? Choose from 5 specializations and build in-demand skills in Ghana
                                such as Data Science, AI & Automation, and E-Commerce. 96% of our learners would recommend Nexford to a friend!
                            </p>
                            <p>So, what are you waiting for? Discover a next-generation MBA today.</p>
                        </div>

                        <a href="https://www.nexford.org/ghana-online-mba?utm_source=careersinghana&utm_medium=email&utm_campaign=traffic" ><img src={Nexford} /></a>
                    </div>
                </section>
                <Footer />
            </div>

        </>
    )
}

export default Main