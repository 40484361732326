import React from 'react'
import Header from '../Header/Main'
import Breadcrumb from '../Breadcrumb/Main'
import Servicepage from '../Menservice/Servicepage'
import Footer from '../Footer/Main'

function Main() {
    return (
        <>
            <div className="page-wrapper">
                <Header />
                <Breadcrumb subtitle="Home" title="Career Advice" />
                <section className="service-one service-one--page">
                    <div className="container">


                        <h3 className="mt-4">
                            7 Types of Companies You Should Never Work For
                        </h3>


                        No matter how desperate you are for a job, or how annoyed you may be at your current gig, there are some companies you’re better off not working for. Even if the money is good, the role seems irresistible, and even your friends say it’s worth a shot, accepting a role at a crappy company can set your career back and even jeopardize your future success. So how do you know which companies to steer clear of? Here at Glassdoor, we don’t like to point fingers because all companies have the tools to change. Nevertheless, here are 7 types of companies that might as well have “Do not apply” flashing on a neon sign in the window

                        <h4 className="mt-4">1. The High Turnover Outfit</h4>
                        <p><span className="fw-bold">Red flags:</span> Key roles pop up consistently on a company’s job site.</p>

                        <p><span className="fw-bold">How bad is it:</span> A company should not be on the hunt for the same important roles in management or leadership every six months, and if they are that means that they have fallen into a hire-and-fire cycle. This can indicate a few things. One, leadership may be very fickle; unable to land on the specific qualities they want in a candidate. Two, the company may have a bad internal culture which makes retention nearly impossible, no matter how talented the new hires may be. Three, top level goals may be as fleeting as the talent. </p><p><span className="fw-bold">What to do:</span> Companies with high turnover won’t deliver on their promises and may just be a waste of time</p>

                        <h4 className="mt-4">2. The Culture Clash Corp</h4>
                        <p><span className="fw-bold">Red flags:</span> Negative employee reviews, lack of focus on a true employee experience, recruiters evading your questions.</p>

                        <p><span className="fw-bold">How bad is it:</span> A poor company culture may not seem like a deal breaker, but it should be. Recently, we’ve seen a handful of examples where company culture has significantly handicapped public perception. Even if a company’s poor culture hasn’t played out publicly, it can be bad for your career. It’s well known that a positive company culture can drive financial performance and a productive workforce. Therefore, a negative culture can do the exact opposite. </p><p><span className="fw-bold">What to do:</span> Avoid companies who tout their ping-pong tournament but won’t allow you speak to existing employees about their experiences. Consider ending interview discussions with companies that evade questions about culture. And just say no to places who define “hard work” as 15-hour days and long weekend email threads.</p>

                        <h4 className="mt-4">3. The Curb Appealer</h4>
                        <p><span className="fw-bold">Red flags:</span> Pristine and ideal image in marketing materials and publicity, however, the day-to-day operation is far from glamorous. Only the leaders have what can pass as offices, staff is dispersed amongst shoddy cubicles, lighting is awful, technology is from the '90s, and let’s not get started on the break room.</p>

                        <p><span className="fw-bold">How bad is it:</span> We’ve all seen them: the amazingly beautiful house on the block with the pristine lawn and the paint job that always manages to look fresh, even in the winter. These are the homes with curb appeal. They are the envy of every neighbor and look like a million bucks. But, have you ever been inside a home with massive curb appeal? Unless you’re in a really impressive neighborhood, they can have less-than-ideal interiors. The same can go for companies that are featured in all the top publications, have the coolest website, the most cutting-edge ad campaigns, and marketing materials. However, inside might tell another story. </p><p><span className="fw-bold">What to do:</span> Do your due diligence before you apply to a company to look inside its offices get a sense of the digs and see if it’s a place you want to spend 40+ hours a week.</p>

                        <h4 className="mt-4">4. The Top Heavy Business</h4>
                        <p><span className="fw-bold">Red flags:</span> Too many executives brainstorming, too few employees tasked with executing</p>

                        <p><span className="fw-bold">How bad is it:</span> The three leading drivers of long-term employee satisfaction include culture and values, career opportunities, and trust in senior leadership. That does not mean, however, that all of the emphasis should be placed on attracting top executives to a company. Sure, it’s important to have phenomenal leadership, but when you read reviews of a company be sure to note how much emphasis is placed on rank & file employees. All team members are important and you should see that reflected in employee reviews of the company and in their hiring practices.

                        </p><p><span className="fw-bold">What to do:</span> Ask yourself: Who’s getting promoted internally? Or are outsiders filling key positions? Why are there 10 SVPs, but only 100 employees? If the answers to these questions puzzle you, then you may be looking at a top-heavy company.</p>

                        <h4 className="mt-4">5. The Perpetual Promisor</h4>
                        <p><span className="fw-bold">Red flags:</span>Unfulfilled corporate expectations, employees report a lack of trust in the CEO, inability to live up to brand promises.</p>



                        <p><span className="fw-bold">How bad is it:</span> In the era of transparency, most companies are fully aware that they must attract the best talent with full, robust and competitive packages. In order to do this, they make promises. Companies make promises around the job, the compensation package, the culture and the brand. Furthermore, companies have a brand promise that is a manifestation of its core business strategy. The problem with promises, however, they can be broken. Beware of companies that make promise after promise after promise.

                            Let’s boil this down to something tangible: a promotion. Your boss at XYZ company promises you a promotion based on your hard work and value to the company. You start to get excited about the new role and, of course, the increased pay. However, a month goes by and no one has mentioned anything. You follow up, and your boss routes you to HR. All you get from HR is blank stares and shrugged shoulders. Finally, weeks later you’ve been sent an email stating that your promotion has been delayed. Sigh. Broken promise.

                        </p><p><span className="fw-bold">What to do:</span> Consider leaving. A company is only as good as its brand promise and the trust of its employees. Without these two things, it is doomed to fail.</p>

                        <h4 className="mt-4">6. The "Stagnator"</h4>
                        <p><span className="fw-bold">Red flags:</span> Lack of learning opportunities, fails to promote mentorship, offers little more than the role you’ve applied for</p>



                        <p><span className="fw-bold">How bad is it:</span> You’ve got the offer from the firm or company of your dreams. The money is right, the role is perfect and your future coworkers are people you’d absolutely grab a beer with after work. So what, there’s no learning-and-development offerings. So what, the hiring manager evaded your questions about your future goals. No big deal, right? Hardly. The stagnant company is one to stay away from as well because it places little to no emphasis on helping you meet your long-term career goals. While this type of company may work for some job seekers looking for a very particular type of job, for many it presents a dead-end.

                        </p><p><span className="fw-bold">What to do:</span> Working at a "stagnator" means that you’ll likely be back on the job hunt in 12 to 18 months. Remember, to stagnate is a verb that means, “to cease developing; become inactive or dull.” This is not what you want for your career.</p>



                        <h4 className="mt-4">7. The Directionless Ship.</h4>
                        <p><span className="fw-bold">Red flags:</span> No clear plan for the future, employees don't know long-term goals, senior leadership fails to adequately communicate.</p>



                        <p><span className="fw-bold">How bad is it:</span> Beware of the Titanic companies that tout all the bells and whistles, but lack a clear direction. It's these companies that inevitably hit the icebergs or big challenges over time and can become in danger of sinking. Companies should be forthright about where they stand financially, where they see themselves going, and should be willing to talk about any major challenges. If the hiring team is unable to discuss openly what direction the company hoping to go, it may be a clue that they lack a plan for growth and that the foundation may be shaky.

                        </p><p><span className="fw-bold">What to do:</span> No matter how promising a company looks to the media or how much buzz surrounds the company’s latest product, if the value proposition and forecast are unclear, the company does not have a winning strategy.</p>


                    </div>
                </section>
                <Footer />
            </div>

        </>
    )
}

export default Main