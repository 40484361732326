import React from 'react'
import Header from '../Header/Main'
import Breadcrumb from '../Breadcrumb/Main'
import Contactform from '../Mencontact/Contactform'

import Footer from '../Footer/Main'
import AddJobForm from '../AddJobForm/Main'

function Main() {
  return (
    <div>
      <div className="page-wrapper">
        <Header />
        <Breadcrumb subtitle="Home" title="Add Job" />
        <AddJobForm />

        <Footer />
      </div>
    </div>
  )
}

export default Main