import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Chooseimg1 from '../../assets/images/resources/about-circle-1.png'
import Chooseimg2 from '../../assets/images/resources/about-circle-2.png'
import ChooseShape from '../../assets/images/shapes/about-circle-shape.png'
import { getStrapiData } from '../../api.controller'

function Whychoose({ data }) {


    return (
        <div>
            <section className="why-choose-two">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6">
                            <div className="why-choose-two__image">
                                <img style={{ borderRadius: "50%" }} src={Chooseimg1} alt="" />
                                <img style={{ borderRadius: "50%" }} src={Chooseimg2} className="why-choose-two__image__two" alt="" />
                                <img src={ChooseShape} className="why-choose-two__image__shape" alt="" />
                                {/* <div className="why-choose-two__circle">
                                    <span className="video-popups">
                                        <i className="icon-talent-search" />
                                    </span>
                                    <div className="curved-circle">
                                        <div
                                            className="curved-circle--item"
                                            data-circle-text-options='{
                                                "radius": 92,
                                                "forceWidth": true,
                                                "forceHeight": true
                                            }'
                                        >
                                            new human resources theme
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                        <div className="col-xl-6">
                            <div className="about-one__content">
                                <div className="sec-title text-left">
                                    <h6 className="sec-title__tagline">about careers in ghana</h6>
                                    <h3 className="sec-title__title">
                                        {data && data.about.heading}
                                    </h3>
                                </div>
                                <p className="about-one__content__text">
                                    {data && data.about.description}
                                </p>
                                <div className="row">
                                    <div className="col-md-6">
                                        {data && <ul className="about-one__content__list">
                                            {data.about.contentlist.map(item => <li>
                                                <span className="fa fa-check-circle" />
                                                {item.contentlistitem}
                                            </li>)}
                                        </ul>}
                                        {/* <div className="about-one__content__quote">
                                            Pathway for both employer and employee. So they highly efficient
                                            manufactured products.
                                        </div>
                                        <div className="about-one__content__author">
                                            <div className="about-one__content__author__thumb">
                                                <img src={Aboutauthor} alt="hiredots" />
                                            </div>
                                            <div className="about-one__content__author__meta">
                                                <img src={Aboutsign} alt="hiredots" />
                                                Co Founder
                                            </div>
                                        </div> */}
                                    </div>
                                    {/* <div className="col-md-6">
                                        <div className="about-one__award">
                                            <div className="about-one__award__content">
                                                <div className="about-one__award__icon">
                                                    <i className="icon-trophy" />
                                                </div>
                                                <div className="about-one__award__count count-box">
                                                    +
                                                    <span className="count">{counts.count}</span>
                                                </div>
                                            </div>
                                            <p className="about-one__award__text">Wonderful awards</p>
                                        </div>
                                        <Link to="/about" className="hiredots-btn hiredots-btn--base">
                                            <span>Discover more</span>
                                        </Link>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Whychoose