import React, { useState, useEffect } from 'react';
import { getStrapiData } from '../../api.controller';

function Funfact() {



    // "stats": [
    // {
    // "id": 1,
    // "text": "Employers",
    // "count": "20",
    // "icon": "icon-business-idea"
    // },
    // ]

    const [counts, setCounts] = useState();


    useEffect(() => {
        getStrapiData("home-page").then(response => {
            console.log(response);
            setCounts(response.data.stats);
        });
    }, []);

    return (
        <div>
            {counts && <section className="funfact-one">
                <div className="container">
                    <div className="row">

                        {counts.map(item => {

                            return <div className="col-lg-3 col-md-6">
                                <div className="funfact-one__item">
                                    <div className="funfact-one__icon">
                                        <span className={item.icon} />
                                    </div>
                                    <div className="funfact-one__count count-box">
                                        <span className="count">{item.count}</span>
                                    </div>
                                    <p className="funfact-one__text">{item.text}</p>
                                </div>
                            </div>
                        })}

                    </div>
                </div>
            </section>}
        </div>
    )
}

export default Funfact