import React from 'react'
import { Route, Routes, } from 'react-router-dom';
import Homeone from '../components/Homeone/Main';
import HomeTwo from '../components/HomeTwo/Main'
import HomeThree from '../components/HomeThree/Main'
import About from '../components/About/Main';
import TeamDetails from '../components/TeamDetails/Main'
import Jobs from '../components/Jobs/Main'
import History from '../components/History/Main'
import Faq from '../components/Faq/Main'
import Services from '../components/Services/Main'
import ServiceConsulting from '../components/Service-Consulting/Main'
import ServiceResources from '../components/Service-resources/Main'
import ServiceHydro from '../components/Service-Hydro/Main'
import ServiceProgram from '../components/Service-Program/Main'
import ServiceTraining from '../components/Service-Training/Main'
import ServiceManagement from '../components/Service-Management/Main'
import Project from '../components/Project/Main'
import ProjectDetails from '../components/ProjectDetails/Main'
import Blog from '../components/Blog/Main'
import CareerListing from '../components/CareerListing/Main'
import BlogDetails from '../components/BlogDetails/Main'
import Contact from '../components/Contact/Main';
import ExecutiveSearch from '../components/ExecutiveSearch/Main';
import Recruitment from '../components/Recruitment/Main';
import PayrollOutsourcing from '../components/PayrollOutsourcing/Main';
import BackgroundChecks from '../components/BackgroundChecks/Main';
import ConsultancyServices from '../components/ConsultancyServices/Main';
import Clients from '../components/Clients/Main';
import Login from '../components/Login/Main';
import AdminLogin from '../components/AdminLogin/Main';
import AddJob from '../components/AddJob/Main';
import AddBlog from '../components/AddBlog/Main';
import AddGalleryPicture from '../components/AddGalleryPicture/Main';
import AddCareerListing from '../components/AddCareerListing/Main';
import Gallery from '../components/Gallery/Main';
import Register from '../components/Register/Main';
import JobDetail from '../components/JobDetail/Main';
import CareerAdvice from '../components/CareerAdvice/Main';
import NexfordUniversity from '../components/NexfordUniversity/Main';
// import Resources from '../components/Resources/Main';

function index() {
    return (
        <>
            <Routes>
                <Route path="/" element={<Homeone />} />
                {/* <Route path='/hometwo' element={<HomeTwo />} />
                <Route path='/homethree' element={<HomeThree />} /> */}
                <Route path="/about" element={<About />} />
                {/* <Route path="/teamdetails" element={<TeamDetails />} /> */}
                <Route path="/jobs" element={<Jobs />} />
                <Route path="/jobs/:id" element={<JobDetail />} />
                {/* <Route path="/history" element={<History />} />
                <Route path="/faq" element={<Faq />} /> */}
                <Route path="/services" element={<Services />} />
                {/* <Route path="/service-consulting" element={<ServiceConsulting />} />
                <Route path="/service-resources" element={<ServiceResources />} />
                <Route path="/service-hydro" element={<ServiceHydro />} />
                <Route path="/service-program" element={<ServiceProgram />} />
                <Route path="/service-training" element={<ServiceTraining />} />
                <Route path="/service-management" element={<ServiceManagement />} /> */}
                {/* <Route path="/project" element={<Project />} />
                <Route path="/project-details" element={<ProjectDetails />} /> */}
                <Route path="/blog" element={<Blog />} />
                <Route path="/career-listings" element={<CareerListing />} />
                <Route path="/blog/:id" element={<BlogDetails />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/executive-search" element={<ExecutiveSearch />} />
                <Route path="/recruitment" element={<Recruitment />} />
                <Route path="/payroll-outsourcing" element={<PayrollOutsourcing />} />
                <Route path="/background-checks" element={<BackgroundChecks />} />
                <Route path="/consultancy-services" element={<ConsultancyServices />} />
                <Route path="/our-clients" element={<Clients />} />
                <Route path="/login" element={<Login />} />
                <Route path="/admin-login" element={<AdminLogin />} />
                <Route path="/register" element={<Register />} />
                <Route path="/add-job" element={<AddJob />} />
                <Route path="/add-blog" element={<AddBlog />} />
                <Route path="/add-career-listing" element={<AddCareerListing />} />
                <Route path="/nexford-university" element={<NexfordUniversity />} />
                <Route path="/gallery" element={<Gallery />} />
                <Route path="/add-gallery-picture" element={<AddGalleryPicture />} />
                {/* 
                <Route path="/interview-skills" element={<InterviewSkills />} />
                <Route path="/nexford-university" element={<NexfordUniversity />} /> */}
                {/* <Route path="/resources" element={<Resources />} /> */}
            </Routes>
        </>
    )
}

export default index