import React from 'react'
import Header from '../Header/Main'
import Breadcrumb from '../Breadcrumb/Main'

import Footer from '../Footer/Main'
import AddGalleryPictureForm from '../AddGalleryPictureForm/Main'

function Main() {
  return (
    <div>
      <div className="page-wrapper">
        <Header />
        <Breadcrumb subtitle="Home" title="Add Gallery Picture" />
        <AddGalleryPictureForm />
        <Footer />
      </div>
    </div>
  )
}

export default Main