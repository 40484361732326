import React from 'react'
import Header from '../Header/Main'
import Breadcrumb from '../Breadcrumb/Main'
import Servicepage from '../Menservice/Servicepage'
import Footer from '../Footer/Main'
import { Link } from 'react-router-dom'

function Main() {
    return (
        <>
            <div className="page-wrapper">
                <Header />
                <Breadcrumb subtitle="Home" title="Background Checks" />
                <section className="service-one service-one--page">
                    <div className="container">



                        <p>Careers in Ghana (CIG) Background Checks is the most flexible, and most delightful employment background check experience you can find We offer background checks for all industries, including accounting, finance, and banking; construction; staffing; IT and technology; retail; education; healthcare, and more. And we make it easy for all roles to run employment background checks, including human resources teams, recruiters, and HR generalists; team leads; hiring managers; department heads and more.</p>

                        <h3 className="mt-4">Our aim is to provide simply better background screening for you and your candidates</h3>

                        We’ve built the most advanced background check toolset in the industry to make hiring faster, easier, more accurate, and lower risk. Our background seaarch service includes

                        <ul className="about-one__content__list">
                            <li>
                                <span className="fa fa-check-circle" />
                                Reference Checks
                            </li>
                            <li>
                                <span className="fa fa-check-circle" />
                                Employment Checks
                            </li>
                            <li>
                                <span className="fa fa-check-circle" />
                                Education and Qualification Checks
                            </li>
                            <li>
                                <span className="fa fa-check-circle" />
                                Criminal Checks
                            </li>
                            <li>
                                <span className="fa fa-check-circle" />
                                Professional Checks
                            </li>
                            <li>
                                <span className="fa fa-check-circle" />
                                Social Media Checks
                            </li>
                            <li>
                                <span className="fa fa-check-circle" />
                                Driver Checks - Uber, Yango
                            </li>

                        </ul>

                        <h3 className="mt-4">Reference Checks</h3>
                        <p>An employee reference check from CIG background Checking will provide you with complete transparency and will ensure that you here are no doubts about employment gaps or the experience and qualifications they claim they have..</p>

                        <h3 className="mt-4">Pre-Employment Screening</h3>

                        <p>CIG Pre-employment services is a combination of specialised services for clients in Ghana which provide you with the confidence that candidates are who they say they are and are qualified to do the job you employ them to do.</p>

                        <p>With Employment Verifications, CIG team of experts help reduce your workload by validating your candidate’s employment history</p>

                        <ul className="about-one__content__list">

                            <li>
                                <span className="fa fa-check-circle" />
                                Job History</li>
                            <li>
                                <span className="fa fa-check-circle" />
                                Employment History Checks</li>
                            <li>
                                <span className="fa fa-check-circle" />
                                Education and Qualification Checks</li>
                        </ul>

                        <h3 className="mt-4">Our employment checks confirm</h3>

                        <ul className="about-one__content__list">
                            <li>
                                <span className="fa fa-check-circle" />
                                Job History</li>
                            <li>
                                <span className="fa fa-check-circle" />
                                Place of employment</li>
                            <li>
                                <span className="fa fa-check-circle" />
                                Employment dates</li>
                            <li>
                                <span className="fa fa-check-circle" />
                                Positions held</li>
                        </ul>


                        <h3 className="mt-4">Education Verification Checks</h3>

                        <p>Our Education Verification search confirms the education, degree, training, or certification claims of a candidate are true and identifies potential discrepancies before you hire</p>

                        <p>Education Verification checks contact the universities, colleges, vocational schools, and high schools provided by the candidate to verify credentials:</p>

                        <h3 className="mt-4">Criminal Background Checks</h3>

                        <p>A criminal history record helps you make an informed decision about your candidate and assess any risks associated with bringing someone with a criminal record onboard.</p>

                        <p>A comprehensive criminal background check helps you build a team you can trust, while also mitigating risk and protecting your company’s reputation</p>

                        <h3 className="mt-4">Credit Background Checks & Reports</h3>

                        <p>A credit background check for employment is an important way to manage risk in hiring when you need a candidate with strong fiscal responsibility</p>

                        <h3 className="mt-4">Professional Certificates Verification</h3>

                        <p>Careers in Ghana Professional Verification search confirms the education, degree, training, or certification claims of a candidate are true and identifies potential discrepancies before you hire</p>

                        <h3 className="mt-4">Our Professional verification includes</h3>
                        <ul className="about-one__content__list">
                            <li>
                                <span className="fa fa-check-circle" />
                                Professional License Verification</li>
                            <li>
                                <span className="fa fa-check-circle" />
                                Professional Membership Verification</li>

                        </ul>
                        <h3 className="mt-4">Social Media Checks</h3>
                        <p>Social media background checks reviews a potential employee’s social media and web presence to ensure they are an appropriate fit for a position.</p>

                        <p>Some employers perform a quick scan of public profiles on major social media websites, while others conduct in-depth scans of blogs, comment sections and other traces of a candidate’s digital activity</p>

                        <h3 className="mt-4">Driver Verification</h3>

                        A Driver Screening helps your organization maintain compliance, protect your brand reputation, and assure your customers of safety

                        <ul className="about-one__content__list">
                            <li>
                                <span className="fa fa-check-circle" />
                                Motor Vehicle Ownership Records</li>
                            <li>
                                <span className="fa fa-check-circle" />
                                Driving License Verification</li>

                        </ul>

                        <Link className="hiredots-btn mb-30 mt-60" to="https://app.careersinghana.com/" >Visit our background checks platform to get started</Link>
                    </div>
                </section >
                <Footer />
            </div >

        </>
    )
}

export default Main