import React, { useEffect, useState } from 'react'
import Header from '../Header/Main'
import Breadcrumb from '../Breadcrumb/Main'
import Whychoose from '../Menabout/Whychoose'
import Testimonial from '../Menhomeone/Testimonial'
import Ctathree from '../Menabout/Ctathree'
import Benefit from '../Menhomethree/Benefit'
import Team from '../Menabout/Team'
import Clienttwo from '../Menhometwo/Clienttwo'
import Footer from '../Footer/Main'
import Client from '../Menhomeone/Client'
import { getStrapiData } from '../../api.controller'

function Main() {
    const [data, setData] = useState();
    useEffect(() => {
        getStrapiData("about-page").then(response => {
            setData(response.data);
        }).catch(err => {
            console.log(err);
        })
    }, []);
    return (
        <>
            <div className="page-wrapper">
                <Header />
                <Breadcrumb subtitle="Home" title="About" />
                <Whychoose data={data} />
                <Testimonial data={data} />
                <Ctathree />
                <Benefit />
                <Team />
                <Client hideTitle />
                <Footer />
            </div>
        </>
    )
}

export default Main