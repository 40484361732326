// Authentication

import axios from 'axios';

const handleAxiosError = (error, defaultMessage = "") => {
    return error?.response?.data?.message || defaultMessage || "An error occured";
}

export const login = async (data) => {
    try {
        const url = `${process.env.REACT_APP_BASE_URL}/auth/signin`;
        const response = await axios.post(url, data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response?.data || null;
    } catch (error) {
        throw new Error(handleAxiosError(error, "Unable to login! Please try again"));
    }
}
export const loginAdmin = async (data) => {
    try {
        const url = `${process.env.REACT_APP_BASE_URL}/admins/auth/signin`;
        const response = await axios.post(url, data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response?.data || null;
    } catch (error) {
        throw new Error(handleAxiosError(error, "Unable to login! Please try again"));
    }
}
export const register = async (data) => {
    try {
        const url = `${process.env.REACT_APP_BASE_URL}/users`;
        const response = await axios.post(url, data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response?.data || null;
    } catch (error) {
        throw new Error(handleAxiosError(error, "Unable to register! Please try again"));
    }
}

export const logout = async () => {

}



//Jobs
export const getJobs = async (filter) => {
    try {
        const url = `${process.env.REACT_APP_BASE_URL}/jobs`;
        const response = await axios.get(url, {
            headers: {
                'Content-Type': 'application/json'
            },
            params: filter
        });
        return response?.data || null
    } catch (error) {
        throw new Error(handleAxiosError(error, "Unable to fetch jobs! Please try again"));
    }
}
export const getJobDetailsById = async (id) => {
    try {
        const url = `${process.env.REACT_APP_BASE_URL}/jobs/${id}`;
        const response = await axios.get(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response?.data || null
    } catch (error) {
        throw new Error(handleAxiosError(error, "Unable to fetch job details! Please try again"));
    }
}

export const getBlogDetails = async (id) => {
    try {
        const url = `${process.env.REACT_APP_BASE_URL}/blogs/${id}`;
        const response = await axios.get(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response?.data || null
    } catch (error) {
        throw new Error(handleAxiosError(error, "Unable to fetch blog details! Please try again"));
    }
}

export const createJob = async (data) => {
    try {
        const url = `${process.env.REACT_APP_BASE_URL}/jobs`;
        const response = await axios.post(url, data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return response?.data || null;
    } catch (error) {
        throw new Error(handleAxiosError(error, "Unable to create job! Please try again"));
    }
}

export const createCareerListing = async (data) => {
    try {
        const url = `${process.env.REACT_APP_BASE_URL}/career-listings`;
        const response = await axios.post(url, data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return response?.data || null;
    } catch (error) {
        throw new Error(handleAxiosError(error, "Unable to create job! Please try again"));
    }
}
export const createGalleryPicture = async (data) => {
    try {
        const url = `${process.env.REACT_APP_BASE_URL}/gallery-pictures`;
        const response = await axios.post(url, data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return response?.data || null;
    } catch (error) {
        throw new Error(handleAxiosError(error, "Unable to create gallery picture! Please try again"));
    }
}

export const getStrapiData = async (endpoint) => {
    try {
        const url = `${process.env.REACT_APP_CMS_BASE_API}/${endpoint}`;
        console.log(url);
        const response = await axios.get(url);
        return response
    } catch (error) {
        throw new Error(handleAxiosError(error, "Unable to get carousel images"))
    }
}


//Blogs
export const createBlog = async (data) => {
    try {
        const url = `${process.env.REACT_APP_BASE_URL}/blogs`;
        const response = await axios.post(url, data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return response?.data || null;
    } catch (error) {
        throw new Error(handleAxiosError(error, "Unable to create blog! Please try again"));
    }
}

export const editBlog = async (data) => {

}

export const deleteBlog = async (blogId) => {

}

export const getAllBlogs = async () => {
    try {
        const url = `${process.env.REACT_APP_BASE_URL}/blogs`;
        const response = await axios.get(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response?.data || null
    } catch (error) {
        throw new Error(handleAxiosError(error, "Unable to fetch blogs! Please try again"));
    }
}
export const getAllCareerListingData = async (_selectedCategory) => {
    try {
        const url = `${process.env.REACT_APP_BASE_URL}/career-listings?category=${_selectedCategory}`;
        const response = await axios.get(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response?.data || null
    } catch (error) {
        throw new Error(handleAxiosError(error, "Unable to fetch career listings! Please try again"));
    }
}
export const getAllGalleryPictures = async () => {
    try {
        const url = `${process.env.REACT_APP_BASE_URL}/gallery-pictures`;
        const response = await axios.get(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response?.data || null
    } catch (error) {
        throw new Error(handleAxiosError(error, "Unable to fetch gallery pictures! Please try again"));
    }
}

