import React, { useEffect, useState } from 'react'
import Header from '../Header/Main'
import Breadcrumb from '../Breadcrumb/Main'

import Footer from '../Footer/Main'
import AddBlogForm from '../AddBlogForm/Main'

function Main() {
  const [user, setUser] = useState();
  useEffect(() => {
    const stringifiedUser = localStorage.getItem("userData");
    const _user = JSON.parse(stringifiedUser);
    setUser(_user);
  }, [])
  return (
    <div>
      <div className="page-wrapper">
        <Header />
        <Breadcrumb subtitle="Home" title="Add Blog" />
        {(user && user.userType == "ADMIN") ? <AddBlogForm /> : <h3 className="text-center my-5">Unauthorized</h3>}
        <Footer />
      </div>
    </div>
  )
}

export default Main